import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";

import { useAppState } from "../state/appState";
import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line

import { PageMeta } from "../components/pageMeta";
import BlockZone from "~blockZone";
import BannerSimple from "../components/blockZone/blocks/bannerSimple";

const BlogCard = ({ heading, authoranddate, slug, content, image }) => {
    return (
        <Link className="py-4" to={`/learning-center/${slug.current}`}>
            <div className="group relative">
                <Image {...image} />
                <div className="z-10 absolute flex flex-col items-center justify-center inset-0 bg-dark bg-opacity-30 lg:opacity-0 group-hover:opacity-100 transition-all duration-200">
                    <div className="blockH10 text-warm-white">Read More</div>
                </div>
            </div>
            <div className="mt-4">
                <h2 className="blockH7 text-teal">{heading}</h2>
                <p className="text-teal blockH9 mt-2">{authoranddate}</p>
            </div>
        </Link>
    );
};

const BlogPortal = ({ data: { page, blogs } }) => {
    const { title, banner, meta } = page || {};
    const { setNavDark } = useAppState();

    useEffect(() => {
        setNavDark(false);
    }, [setNavDark]);

    return (
        <>
            <PageMeta {...meta} />
            <BannerSimple {...banner} />
            <div className="grid px-gutter grid-cols-1 md:grid-cols-3 gap-10 py-gutter bg-warm-white">
                {blogs.edges.map((blog, i) => (
                    <BlogCard key={i} {...blog.node} />
                ))}
            </div>
        </>
    );
};

export default BlogPortal;

export const pageQuery = graphql`
    query blogPortalQuery {
        page: sanityBlogPortal {
            meta {
                metaImage {
                    ...ImageWithPreview
                }
            }
            banner {
                showDiagonal
                videoUrl
                text: _rawText(resolveReferences: { maxDepth: 10 })
                image {
                    ...ImageWithPreview
                }
            }
        }
        blogs: allSanityBlogPost(sort: { fields: [_createdAt], order: DESC }) {
            edges {
                node {
                    meta {
                        metaImage {
                            ...ImageWithPreview
                        }
                    }
                    image: _rawImage(resolveReferences: { maxDepth: 100 })
                    heading
                    slug {
                        current
                    }
                    content: _rawContent(resolveReferences: { maxDepth: 100 })
                    authoranddate
                }
            }
        }
    }
`;
