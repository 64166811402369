import React, { useEffect } from "react";
import { graphql } from "gatsby";

import { useAppState } from "../state/appState";
import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line

// Components
import { PageMeta } from "../components/pageMeta";
import { PageLoader } from "../components/pageLoader";
import BlockZone from "~blockZone";

const PageTemplate = ({ preview = false, data, previewDoc }) => {
    const page = preview ? previewDoc : data && data.page;

    const { meta, pageBlocks } = page || {};

    const { setNavDark } = useAppState();

    useEffect(() => {
        setNavDark(false);
    }, [setNavDark]);

    return (
        <>
            <PageMeta {...meta} />
            {pageBlocks && <BlockZone {...pageBlocks} />}
            <PageLoader />
        </>
    );
};

export default PageTemplate;

export const pageQuery = graphql`
    query standardPageQuery($slug: String!) {
        page: sanityPage(slug: { current: { eq: $slug } }) {
            meta {
                ...MetaCardFields
            }
            pageBlocks {
                ...BlockZone
            }
        }
    }
`;
