import React from "react";
import Image from "gatsby-plugin-sanity-image";
import { RowCarousel } from "../carousels";
import { Link } from "gatsby";

export const OtherBlogs = ({ edges }) => {
    console.log(edges);
    return (
        <div className="grid grid-cols-14 py-20 lg:py-40 overflow-x-hidden bg-warm-white">
            <div className="z-10 col-start-2 col-end-14 row-start-1 lg:text-left blockH2 text-center mb-12 lg:mb-20 text-teal">
                Other blogs
            </div>
            <RowCarousel className="col-start-1 col-end-15 row-start-2 lg:px-gutter">
                {edges.map((edge) => {
                    const {
                        meta: { metaImage },
                        heading,
                        slug,
                        authoranddate,
                    } = edge.node;
                    return (
                        <Link
                            to={`/learning-center/${slug.current}`}
                            className="w-full px-gutter lg:w-1/2 xl:w-1/3 lg:pr-16 lg:pl-0"
                            style={{ flex: "0 0 auto" }}
                        >
                            <div className="group relative aspect-w-3 aspect-h-2">
                                {metaImage && (
                                    <Image
                                        className="absolute inset-0 object-cover"
                                        {...metaImage}
                                    />
                                )}
                                <div className="z-10 absolute flex flex-col items-center justify-center inset-0 text-warm-white bg-dark bg-opacity-30 lg:opacity-0 group-hover:opacity-100 transition-all duration-200">
                                    <div className="blockH10 text-warm-white">
                                        Read More
                                    </div>
                                </div>
                            </div>
                            {heading && (
                                <h3 className="blockH7 text-teal">{heading}</h3>
                            )}
                            {authoranddate && (
                                <p className="text-teal-01 blockH9 mt-2">
                                    {authoranddate}
                                </p>
                            )}
                        </Link>
                    );
                })}
            </RowCarousel>
        </div>
    );
};
