import React, { useCallback, useState, useEffect } from "react";
import { Router } from "@reach/router";

import { useLoads } from "react-loads";

import sanityClient from "../lib/sanityClient";

import HomePage from "../pages/index";
import PageTemplate from "../templates/page";
import PostTemplate from "../templates/post";
import ProjectTemplate from "../templates/project";
import BlogPortal from "../pages/learning-center";

const PreviewPage = ({ document }) => {
    const queryDraft = `*[_id == "${document}"]`;

    const [doc, setDoc] = useState();

    const handlePreviewFetch = useCallback(
        () =>
            sanityClient.fetch(queryDraft).then((response) => {
                setDoc(response[0]);
            }),
        [],
    );

    const { error, isResolved, isPending, isReloading, load } = useLoads(
        "handlePreviewFetch",
        handlePreviewFetch,
        {
            defer: true,
        },
    );

    useEffect(() => {
        load();
        const subscription = sanityClient
            .listen(queryDraft)
            .subscribe((update) => {
                setDoc(update && update.result);
            });
        return () => {
            subscription.unsubscribe();
        };
    }, [0]);

    const renderPreview = () => {
        if (doc) {
            switch (doc._type) {
                case "homePage":
                    return <HomePage previewDoc={doc} preview={true} />;
                case "page":
                    return <PageTemplate previewDoc={doc} preview={true} />;
                case "post":
                    return <PostTemplate previewDoc={doc} preview={true} />;
                case "project":
                    return <ProjectTemplate previewDoc={doc} preview={true} />;
                case "blogPortal":
                    return <BlogPortal previewDoc={doc} preview={true} />;
                default:
                    break;
            }
        }
    };

    return (
        <>
            {(isPending || isReloading) && (
                <div className="ac">
                    <span>Loading</span>
                </div>
            )}
            {isResolved && !isPending && renderPreview()}
        </>
    );
};

const Preview = () => {
    return (
        <Router>
            <PreviewPage path="/preview/:document" />
        </Router>
    );
};

export default Preview;
