import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import { motion } from "framer-motion";
import { PageLoader } from "../components/pageLoader";
import { useAppState } from "../state/appState";
import cx from "classnames";

// Components
import { PageMeta } from "../components/pageMeta";
import { OtherBlogs } from "../components/ui/otherBlogs";
import { PortableTextBlock } from "../components/sanity/portableTextBlock";
import { BannerVideo } from "../components/media/bannerVideo";

const headingVars = {
    hidden: {
        opacity: 0,
        y: 15,
        scale: 0.95,
    },
    visible: {
        opacity: 1,
        y: 0,
        scale: 1,
    },
};

const zVars = {
    hidden: {
        opacity: 1,
        x: "5%",
    },
    visible: {
        opacity: 1,
        x: 0,
    },
};

const Content = ({ content, by }) => {
    return (
        <div className="grid grid-cols-14 pt-24 bg-warm-white">
            <h3 className="col-start-2 col-end-14 md:col-start-3 md:col-end-13 text-teal blockH9">
                {by}
            </h3>
            <PortableTextBlock
                text={content}
                className="prose prose-lg md:prose-2xl col-start-2 col-end-14 md:col-start-3 md:col-end-13 pt-16 text-teal"
            />
        </div>
    );
};

const ProjectBanner = ({ title, videoUrl, image }) => {
    return (
        <div className="bg-warm-white md:pb-14">
            <motion.div
                initial="hidden"
                animate="visible"
                variants={zVars}
                transition={{
                    duration: 0.7,
                }}
                className="md:flex md:items-center"
            >
                {!image && videoUrl && (
                    <BannerVideo
                        video={videoUrl}
                        className="md:w-[1084px] z-0 px-5 pt-5 md:px-10 md:py-10 xl:w-1/2 xl:pr-0"
                    />
                )}
                {image && (
                    <Image
                        className="md:w-7/12 h-full z-0 px-5 pt-5 md:px-10 md:py-10 xl:w-1/2 xl:pr-0"
                        {...image}
                    />
                )}

                {title && (
                    <div className="text-teal pt-5 pb-10 px-5 -mt-10 md:mt-0 md:p-0 md:-ml-28 md:pr-5 lg:p-8 xl:py-12 relative">
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={headingVars}
                            transition={{
                                duration: 0.6,
                                delay: 0.3,
                            }}
                        >
                            <h1 className={cx("bannerH1 w-full md:w-50% ")}>
                                {title}
                            </h1>
                        </motion.div>
                    </div>
                )}
            </motion.div>
        </div>
    );
};

const BlogPost = ({ preview = false, data, previewDoc }) => {
    const page = preview ? previewDoc : data && data.page;
    const { otherBlogs } = data;
    const { heading, meta, image, authoranddate, content } = page || {};

    const { setNavDark } = useAppState();

    useEffect(() => {
        setNavDark(false);
    }, [setNavDark]);

    return (
        <>
            <PageMeta {...meta} />
            <ProjectBanner image={image} title={heading} meta={meta} />
            <Content by={authoranddate} content={content} />
            <OtherBlogs {...otherBlogs} />
            <PageLoader />
        </>
    );
};

export default BlogPost;

export const pageQuery = graphql`
    query blogQuery($slug: String!) {
        page: sanityBlogPost(slug: { current: { eq: $slug } }) {
            image {
                ...ImageWithPreview
            }
            meta {
                ...MetaCardFields
            }
            heading
            content: _rawContent(resolveReferences: { maxDepth: 100 })
            authoranddate
        }
        otherBlogs: allSanityBlogPost(
            filter: { slug: { current: { ne: $slug } } }
        ) {
            edges {
                node {
                    meta {
                        metaImage {
                            ...ImageWithPreview
                        }
                    }
                    heading
                    slug {
                        current
                    }
                    authoranddate
                }
            }
        }
    }
`;
