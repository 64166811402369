import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import { BackgroundVideo } from "../components/media/backgroundVideo";
import { BannerVideo } from "../components/media/bannerVideo";
import { motion } from "framer-motion";
import { PageLoader } from "../components/pageLoader";
import { useAppState } from "../state/appState";
import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line

// Components
import { PageMeta } from "../components/pageMeta";
import { OtherProjects } from "../components/ui/otherProjects";
import BlockZone from "~blockZone";

const zVars = {
    hidden: {
        opacity: 0,
        y: 15,
        scale: 0.95,
    },
    visible: {
        opacity: 1,
        y: 0,
        scale: 1,
    },
};

const textVars = {
    hidden: {
        opacity: 0,
        y: 10,
    },
    visible: {
        opacity: 1,
        y: 0,
    },
};

const ProjectBanner = ({ title, videoUrl, image, projectType }) => {
    return (
        <div className="bg-warm-white md:pb-14">
            <motion.div
                initial="hidden"
                animate="visible"
                variants={zVars}
                transition={{
                    duration: 0.7,
                }}
                className="md:flex md:items-center"
            >
                {!image && videoUrl && (
                    <BannerVideo
                        video={videoUrl}
                        className="md:w-7/12 z-0 px-5 pt-5 md:px-10 md:py-10 xl:w-1/2 xl:pr-0"
                    />
                )}
                {image && (
                    <Image
                        className="md:w-7/12 h-full z-0 px-5 pt-5 md:px-10 md:py-10 xl:w-1/2 xl:pr-0"
                        {...image}
                    />
                )}

                {title && (
                    <div className="text-teal pt-5 pb-10 px-5 -mt-10 md:mt-0 md:p-0 md:-ml-28 md:pr-5 lg:p-8 xl:py-12 relative">
                        <motion.div
                            variants={textVars}
                            initial="hidden"
                            animate="visible"
                            transition={{
                                delay: 0.7,
                                duration: 0.4,
                            }}
                        >
                            {projectType && (
                                <h3 className="blockH7 text-teal pb-[11px] ">
                                    {projectType?.title}
                                </h3>
                            )}
                            <h1 className="bannerH1 text-teal">{title}</h1>
                        </motion.div>
                    </div>
                )}
            </motion.div>
        </div>
    );
};

export const Details = ({ client, projectSize, projectType }) => {
    return (
        <div className="grid grid-cols-1 text-center md:text-left md:grid-cols-3 bg-warm-white py-[75px] font-bold px-gutter gap-x-40 text-teal blockH4">
            {client && (
                <div>
                    <h3 className="text-teal blockH9 pb-[7px]">Client:</h3>
                    <h2>{client}</h2>
                </div>
            )}
            {projectSize && (
                <div className=" pt-10 md:pt-0">
                    <h3 className="text-teal blockH9 pb-[7px]">
                        Project Size:
                    </h3>
                    <h2>{projectSize}</h2>
                </div>
            )}
            {projectType && (
                <div className=" pt-10 md:pt-0">
                    <h3 className="text-teal blockH9 pb-[7px]">
                        Project Type:
                    </h3>
                    <h2>{projectType}</h2>
                </div>
            )}
        </div>
    );
};

const ProjectTemplate = ({ preview = false, data, previewDoc }) => {
    const page = preview ? previewDoc : data && data.page;
    const { otherProjects } = data;
    const {
        title,
        meta,
        image,
        videoUrl,
        pageBlocks,
        client,
        projectSize,
        projectType,
    } = page || {};

    const { setNavDark } = useAppState();

    useEffect(() => {
        setNavDark(false);
    }, [setNavDark]);

    return (
        <>
            <PageMeta {...meta} />
            <ProjectBanner
                videoUrl={videoUrl}
                image={image}
                title={title}
                meta={meta}
                projectType={projectType}
            />
            <Details
                client={client}
                projectSize={projectSize}
                projectType={projectType?.title}
            />
            {pageBlocks && <BlockZone {...pageBlocks} />}
            {/* <OtherProjects {...otherProjects} /> */}
            <PageLoader />
        </>
    );
};

export default ProjectTemplate;

export const pageQuery = graphql`
    query projectQuery($slug: String!) {
        page: sanityProject(slug: { current: { eq: $slug } }) {
            image {
                ...Image
            }
            videoUrl
            meta {
                ...MetaCardFields
            }
            title
            pageBlocks {
                ...BlockZone
            }
            client
            projectSize
            projectType {
                title
            }
        }
        otherProjects: allSanityProject(
            filter: { slug: { current: { ne: $slug } } }
        ) {
            edges {
                node {
                    meta {
                        metaImage {
                            ...Image
                        }
                    }
                    title
                    slug {
                        current
                    }
                }
            }
        }
    }
`;
